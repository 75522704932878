<template>
  <div class="echarts clearfix">
    <div class="echarts-line">
      <div class="echarts-line-box">
        <div class="line-top">
          <img src="@/assets/images/dashboard/line_logo.png" alt="line box" />
          <span>{{ $t('home.PERFORMANCE') }}</span>
        </div>
        <div class="line-content">
          <span class="no_data" v-if="noLineChartData">{{ $t('common.keys.noData') }}</span>
          <div id="line" style="height: 100%; width: 100%;"></div>
        </div>
      </div>
    </div>
    <div class="echarts-margin"></div>
    <div class="echarts-pie">
      <div class="pie-top">
        <img src="@/assets/images/dashboard/pie_logo.png" alt="pie box" />
        <span>{{ $t('home.MOST_TRADED_INSTRUMENTS') }}</span>
      </div>
      <div class="pie-content">
        <span class="no_data" v-if="noPieChartData">{{ $t('common.keys.noData') }}</span>
        <div id="pie" style="height: 100%; width: 100%;"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiFoldline, apiQueryPieTotalVolumeByGoods } from '@/resource';

export default {
  props: ['accountID', 'startTime', 'endTime', 'userID'],
  data() {
    const vm = this;
    return {
      lineChart: null,
      lineOption: {
        tooltip: {
          formatter: function(param) {
            let value = param.value;
            if (param.seriesName != 'OPENED ACCOUNT') {
              value = vm.$options.filters.currency(param.value, '');
            }
            return param.seriesName + '<br />' + param.marker + param.name + ': ' + value;
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          axisLine: {
            lineStyle: {
              color: 'rgb(98, 109, 113)'
            }
          },
          axisLabel: {
            color: 'rgb(113, 123, 126)'
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: 'rgb(99, 110, 114)'
            }
          },
          axisLabel: {
            color: 'rgb(98, 109, 113)'
          },
          splitLine: {
            lineStyle: {
              color: '#4b5562',
              type: 'dashed'
            }
          }
        },
        series: [
          {
            color: 'rgb(0, 76, 178)',
            type: 'line',
            data: [],
            symbolSize: 10,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgb(0, 76, 178)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'transparent' // 100% 处的颜色
                  }
                ]
              }
            }
          }
        ],
        dataZoom: [
          {
            type: 'inside',
            startValue: 0,
            endValue: 5
          }
        ]
      },
      pieChart: null,
      pieOption: {
        color: this.$config.chartColor,
        tooltip: {
          trigger: 'item',
          formatter: function(param) {
            const value = vm.$options.filters.currency(param.value, '');
            return [param.marker + param.name + ':', value, param.percent + '%'].join(' ');
          }
        },
        legend: {
          orient: 'vertical',
          left: '30',
          bottom: '20',
          data: [],
          textStyle: {
            color: 'rgb(98, 109, 113)'
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['35%', '70%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'inside'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '20',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: []
          }
        ]
      },
      noPieChartData: true,
      noLineChartData: true
    };
  },
  watch: {
    lineOption: {
      handler(newVal, oldVal) {
        if (this.lineChart) {
          this.lineChart = this.echarts.init(document.getElementById('line'));
          if (newVal) {
            this.lineChart.setOption(newVal);
          } else {
            this.lineChart.setOption(oldVal);
          }
        }
      },
      deep: true
    },
    pieOption: {
      handler(newVal, oldVal) {
        if (this.pieChart) {
          this.pieChart = this.echarts.init(document.getElementById('pie'));
          if (newVal) {
            this.pieChart.setOption(newVal);
          } else {
            this.pieChart.setOption(oldVal);
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    // 初始化折线图 初始化饼图
    this.lineChart = this.echarts.init(document.getElementById('line'));
    this.pieChart = this.echarts.init(document.getElementById('pie'));

    // 防抖 更新图表大小
    window.onresize = () => {
      this.lineChart.resize();
      this.pieChart.resize();
    };
  },
  methods: {
    updateLineChart(type) {
      if (!this.accountID) return;
      this.noLineChartData = false;
      this.lineOption.yAxis.minInterval = 0;
      let { url, lineChartTit } = this.getUrlAndChartName(type);

      apiFoldline(url, {
        qAccount: this.accountID,
        qUserId: this.userID,
        qStartTime: this.startTime,
        qEndTime: this.endTime
      }).then(resp => {
        const respData = resp.data.data;
        if (resp.data.code === 0 && respData.keys && respData.keys.length > 0) {
          this.$nextTick(() => {
            this.lineOption.series[0].name = lineChartTit;
            this.lineOption.xAxis.data = respData.keys;
            this.lineOption.series[0].data = Array.isArray(respData.values) ? respData.values : [respData.values];
            this.lineOption.dataZoom[0].endValue = respData.keys.length - 1;
            this.noLineChartData = false;
          });
        } else {
          this.noLineChartData = true;
        }
      });
    },
    updatePieChart() {
      if (!this.accountID) return;
      this.noPieChartData = false;

      apiQueryPieTotalVolumeByGoods({
        qAccount: this.accountID,
        qUserId: this.userID,
        qStartTime: this.startTime,
        qEndTime: this.endTime
      }).then(resp => {
        const respData = resp.data.data;
        if (resp.data.code === 0 && respData.keys != undefined && respData.keys.length > 0) {
          let pieData = [];
          let legendData = [];
          respData.keys.map((item, idx) => {
            pieData.push({
              name: item,
              value: Array.isArray(respData.values) ? respData.values[idx] : respData.values
            });
            legendData.push(item);
          });
          this.pieOption.series[0].data = pieData;
          this.pieOption.legend.data = legendData;
          this.noPieChartData = false;
        } else {
          this.noPieChartData = true;
        }
      });
    },
    getUrlAndChartName(type) {
      // REBATE  佣金
      // TOTAL_VOLUME  交易量
      // NET_FUNDING   净入金
      // DEPOSITS   入金
      // WITHDRAW  出金
      // OPEND_ACCOUNT  新开账号数
      switch (type) {
        case 'REBATE':
          return {
            url: 'queryFoldLineTotalCommission',
            lineChartTit: this.$t('home.REBATE')
          };
        case 'TOTAL_VOLUME':
          return {
            url: 'queryFoldLineTotalVolume',
            lineChartTit: this.$t('home.TOTAL_VOLUME')
          };
        case 'NET_FUNDING':
          return {
            url: 'getNetDepositDetail',
            lineChartTit: this.$t('home.NET_FUNDING')
          };
        case 'DEPOSITS':
          return {
            url: 'getDepositDetail',
            lineChartTit: this.$t('home.DEPOSITS')
          };
        case 'WITHDRAW':
          return {
            url: 'getWithdrawalDetail',
            lineChartTit: this.$t('home.WITHDRAW')
          };
        case 'OPEND_ACCOUNT':
          this.lineOption.yAxis.minInterval = 1;
          return {
            url: 'queryFoldLineTotalNewOpenUser',
            lineChartTit: this.$t('home.OPEND_ACCOUNT')
          };
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/home/chart.scss';
</style>
