<template>
  <div id="home">
    <div class="wrapper-main">
      <div class="wrapper-content">
        <AccountNumber ref="accountNumber" @accountCallback="init"></AccountNumber>
        <div class="calendar_content">
          <dateRangePicker v-bind:start-date.sync="startTime" v-bind:end-date.sync="endTime"></dateRangePicker>
          <div class="right_box">
            <el-button class="btn-red" @click="setStartAndEndDate" data-testid="UPDATE">{{
              $t('home.UPDATE')
            }}</el-button>
          </div>
        </div>

        <div class="center">
          <div class="left">
            <div class="calendar-content-bottom">
              <ul class="clearfix">
                <li
                  @click="currLineChartStatus = 'REBATE'"
                  :class="{ active: currLineChartStatus === 'REBATE' }"
                  data-testid="REBATE"
                >
                  <div class="top">
                    <span>{{ $t('home.REBATE') }}</span>
                  </div>
                  <div class="bottom">
                    <span v-textOverflow>
                      <span v-if="ibDataPermission && fundsDataPermission">
                        {{ currAccountCurrency | currencySymbol }}
                        <font class="reduced-font-size">{{ accountDatas.totalCommission | currency('') }}</font>
                      </span>
                      <span v-else>***</span>
                    </span>
                  </div>
                  <img
                    @click="goToRebateReport"
                    src="@/assets/images/dashboard/more.png"
                    class="calendar_more"
                    data-testid="goToRebateReport"
                  />
                </li>
                <li
                  @click="currLineChartStatus = 'TOTAL_VOLUME'"
                  :class="{ active: currLineChartStatus === 'TOTAL_VOLUME' }"
                  data-testid="TOTAL_VOLUME"
                >
                  <div class="top">
                    <span>{{ $t('home.TOTAL_VOLUME') }}</span>
                  </div>
                  <div class="bottom">
                    <span v-textOverflow>
                      <span v-if="ibDataPermission" class="reduced-font-size">{{
                        accountDatas.totalVolume | currency('')
                      }}</span>
                      <span v-else>***</span>
                    </span>
                  </div>
                  <img
                    @click="goToRebateReport"
                    src="@/assets/images/dashboard/more.png"
                    class="calendar_more"
                    data-testid="goToRebateReport"
                  />
                </li>
                <li
                  @click="currLineChartStatus = 'NET_FUNDING'"
                  :class="{ active: currLineChartStatus === 'NET_FUNDING' }"
                  data-testid="NET_FUNDING"
                >
                  <div class="top">
                    <span>{{ $t('home.NET_FUNDING') }}</span>
                  </div>
                  <div class="bottom">
                    <span v-textOverflow>
                      <span v-if="ibDataPermission">
                        {{ currAccountCurrency | currencySymbol }}
                        <font class="reduced-font-size">{{ accountDatas.netDeposit | currency('') }}</font>
                      </span>
                      <span v-else>***</span>
                    </span>
                  </div>
                  <img
                    @click="goToIbReport"
                    src="@/assets/images/dashboard/more.png"
                    class="calendar_more"
                    data-testid="goToIbReport"
                  />
                </li>
                <li
                  @click="currLineChartStatus = 'DEPOSITS'"
                  :class="{ active: currLineChartStatus === 'DEPOSITS' }"
                  data-testid="DEPOSITS"
                >
                  <div class="top">
                    <span>{{ $t('home.DEPOSITS') }}</span>
                    <!-- <img src="@/assets/images/up.png" alt /> -->
                  </div>
                  <div class="bottom">
                    <span v-textOverflow>
                      <span v-if="ibDataPermission">
                        {{ currAccountCurrency | currencySymbol }}
                        <font class="reduced-font-size">{{ accountDatas.totalDeposit | currency('') }}</font>
                      </span>
                      <span v-else>***</span>
                    </span>
                  </div>
                  <img
                    @click="goToIbReport"
                    src="@/assets/images/dashboard/more.png"
                    class="calendar_more"
                    data-testid="goToIbReport2"
                  />
                </li>
                <li
                  @click="currLineChartStatus = 'WITHDRAW'"
                  :class="{ active: currLineChartStatus === 'WITHDRAW' }"
                  data-testid="WITHDRAW"
                >
                  <div class="top">
                    <span>{{ $t('home.WITHDRAW') }}</span>
                    <!-- <img src="@/assets/images/down.png" alt /> -->
                  </div>
                  <div class="bottom">
                    <span v-textOverflow>
                      <span v-if="ibDataPermission">
                        {{ currAccountCurrency | currencySymbol }}
                        <font class="reduced-font-size">{{ accountDatas.totalWithdrawal | currency('') }}</font>
                      </span>
                      <span v-else>***</span>
                    </span>
                  </div>
                  <img
                    @click="goToIbReport"
                    src="@/assets/images/dashboard/more.png"
                    class="calendar_more"
                    data-testid="goToIbReport3"
                  />
                </li>
                <li
                  @click="currLineChartStatus = 'OPEND_ACCOUNT'"
                  :class="{ active: currLineChartStatus === 'OPEND_ACCOUNT' }"
                  data-testid="OPEND_ACCOUNT"
                >
                  <div class="top">
                    <span>
                      <span>{{ $t('home.OPEND_ACCOUNT') }}</span>
                    </span>
                  </div>
                  <div class="bottom">
                    <p v-textOverflow>
                      <font v-if="ibDataPermission" class="reduced-font-size">{{ accountDatas.newOpenUserTotal }}</font>
                      <span v-else>***</span>
                    </p>
                  </div>
                  <img
                    @click="goToIbReport('openedAccounts')"
                    src="@/assets/images/dashboard/more.png"
                    class="calendar_more"
                    data-testid="goToIbReport4"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div class="rigth">
            <Rebate
              ref="rebate"
              :isShowProfit="true"
              :userID="userID"
              @accountIdAndCurrency="getAccountIdAndCurrency"
            ></Rebate>
          </div>
        </div>

        <Chart ref="chart" :accountID="accountID" :startTime="startTime" :endTime="endTime" :userID="userID"></Chart>

        <div class="tables clearfix">
          <div class="table-list">
            <div class="table-line">
              <div class="table-list-top">
                <img src="@/assets/images/dashboard/table_list1.png" />
                <span>{{ $t('home.TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH') }}</span>
              </div>
              <div class="table_list_content">
                <el-table :data="top6" height="300px" style="width: 100%">
                  <el-table-column
                    prop="agentLowAccount"
                    :label="$t('home.Account') + ' No'"
                    width="120"
                  ></el-table-column>
                  <el-table-column prop="name" :label="$t('home.Name')">
                    <template slot-scope="scope">
                      <span v-textOverflow v-html="scope.row.name" />
                    </template>
                  </el-table-column>
                  <el-table-column prop="volume" :label="$t('home.Volume')" width="120">
                    <template slot-scope="topPerformScope">{{ topPerformScope.row.volume | currency('') }}</template>
                  </el-table-column>
                  <el-table-column prop="commission" :label="$t('home.Rebate')" width="120">
                    <template slot-scope="topPerformScope">
                      {{
                        topPerformScope.row.commission === '***'
                          ? '***'
                          : $options.filters.currency(topPerformScope.row.commission, '')
                      }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <img src="@/assets/images/dashboard/more.png" class="table_more" />
            </div>
          </div>
          <div class="table-list">
            <div class="table-line">
              <div class="table-list-top">
                <img src="@/assets/images/dashboard/table_list2.png" />
                <span>{{ $t('home.RECENTLY_OPENED_ACCOUNTS') }}</span>
              </div>
              <div class="table_list_content">
                <el-table :data="nearestOpenAccount" height="300px" style="width: 100%">
                  <el-table-column
                    prop="tradeAccount"
                    :label="$t('home.Account') + ' No'"
                    width="120"
                  ></el-table-column>
                  <el-table-column prop="userName" :label="$t('home.Name')">
                    <template slot-scope="scope">
                      <span v-textOverflow v-html="scope.row.userName"></span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="balance" :label="$t('home.Balance')" width="120">
                    <template slot-scope="scope">
                      <!-- -0.00000009表示后台未查到数据，则用 - 显示 -->
                      <span v-if="scope.row.balance == -0.00000009">-</span>
                      <span v-else>{{ scope.row.balance | currency('') }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <img src="@/assets/images/dashboard/more.png" class="table_more" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from './chart';
import Rebate from '@/components/Rebate';
import AccountNumber from '@/components/form/AccountNumber';
import DateRangePicker from '@/components/DateRangePicker';

import {
  apiGetNearestOpenAccount,
  apiQueryRebateVolumeList,
  apiQueryTotalCommission,
  apiGetNewOpenUserTotal
} from '@/resource';
export default {
  data() {
    return {
      userID: this.$store.state.common.CUID,
      startTime: '',
      endTime: '',
      top6: [],
      nearestOpenAccount: [],
      accountID: '',
      currAccountCurrency: '',
      accountDatas: {
        // 总手数
        totalVolume: 0,
        // 总入金
        totalDeposit: 0,
        // 净入金
        netDeposit: 0,
        // 总出金
        totalWithdrawal: 0,
        // 总佣金
        totalCommission: 0,
        // 可用余额
        availableBalance: 0,
        // 新开账号数
        newOpenUserTotal: 0
      },
      // 当前显示的什么标签的趋势图
      currLineChartStatus: 'REBATE'
    };
  },
  methods: {
    init() {
      this.$refs.rebate.init();
    },
    initData() {
      if (this.accountID) {
        Promise.all([
          this.getNearestOpenAccount(),
          this.queryRebateVolumeList(),
          this.queryTotalCommission(),
          this.getNewOpenUserTotal()
        ]);
      }
      // 每次请求数据，折线图恢复为默认字段
      this.currLineChartStatus = 'REBATE';
      // 更新折线图
      this.$nextTick(() => {
        this.$refs.chart.updateLineChart(this.currLineChartStatus);
        this.$refs.chart.updatePieChart();
      });
    },
    // 获取 最新6个开户账号
    getNearestOpenAccount() {
      apiGetNearestOpenAccount({
        mt4account: this.accountID
      }).then(resp => {
        if (resp.data.code === 0) this.nearestOpenAccount = resp.data.data;
      }, this.$handleError);
    },
    // 获取佣金top6
    queryRebateVolumeList() {
      apiQueryRebateVolumeList({
        qUserId: this.userID,
        qAccount: this.accountID
      }).then(resp => {
        if (resp.data.code === 0) {
          this.fundsDataPermission
            ? (this.top6 = resp.data.data)
            : (this.top6 = resp.data.data.map(item => {
                item.commission = '***';
                return item;
              }));
        }
      }, this.$handleError);
    },
    // 获取 佣金 交易量 净入金 总入金 总出金
    queryTotalCommission() {
      apiQueryTotalCommission({
        qUserId: this.userID,
        qAccount: this.accountID,
        qStartTime: this.startTime,
        qEndTime: this.endTime
      }).then(resp => {
        if (resp.data.code === 0) {
          const respData = resp.data.data;
          Object.keys(respData).map(key => {
            this.$set(this.accountDatas, key, respData[key]);
          });
        }
      }, this.$handleError);
    },
    // 获取 新开账号数
    getNewOpenUserTotal() {
      apiGetNewOpenUserTotal({
        mt4account: this.accountID,
        startTime: this.startTime,
        endTime: this.endTime
      }).then(resp => {
        if (resp.data.code === 0) this.$set(this.accountDatas, 'newOpenUserTotal', resp.data.data);
      }, this.$handleError);
    },
    setStartAndEndDate() {
      this.$nextTick(() => this.initData());
    },
    getAccountIdAndCurrency(accountID, currency) {
      this.accountID = accountID;
      this.currAccountCurrency = currency;
      if (this.startTime && this.endTime) this.initData();
    },
    goToIbReport() {
      const cardMethod = this.currLineChartStatus == 'OPEND_ACCOUNT' ? `cardMethod=openedAccounts` : '';
      this.$router.push(`ibReport?${cardMethod}`);
    },
    goToRebateReport() {
      this.$router.push(`rebateReport`);
    }
  },
  watch: {
    currLineChartStatus(newStatus) {
      this.$nextTick(() => this.$refs.chart.updateLineChart(newStatus));
    }
  },
  components: { Chart, Rebate, AccountNumber, DateRangePicker },
  computed: {
    ibDataPermission() {
      return this.$store.state.common.permissionIds.includes(2) || !this.$store.state.common.isSubUser;
    },
    fundsDataPermission() {
      return this.$store.state.common.permissionIds.includes(3) || !this.$store.state.common.isSubUser;
    },
    lang() {
      return this.$store.state.common.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/home/index.scss';
</style>
